var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules",staticClass:"brow"},[_c('b-img',{staticStyle:{"height":"intrinsic","display":"block","margin":"50px auto 30px"},attrs:{"src":require('@/assets/images/logo/logo-kop.png'),"alt":"logo","width":"200"}}),(_vm.done)?_c('b-card-code',{staticStyle:{"width":"90%","margin":"auto"},attrs:{"title":"Form Keluhan"}},[_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(" Terima Kasih telah mengisi form keluhan KAN-Jabung ")])]):_c('b-card-code',{staticStyle:{"width":"90%","margin":"auto"},attrs:{"title":"Form Keluhan"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Perihal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Perihal","label-for":"Perihal"}},[_c('b-form-input',{attrs:{"id":"Perihal","placeholder":"Tulis Perihal"},model:{value:(_vm.Perihal),callback:function ($$v) {_vm.Perihal=$$v},expression:"Perihal"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Kontak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kontak","label-for":"Kontak"}},[_c('b-form-input',{attrs:{"id":"Kontak","placeholder":"Tulis Kontak"},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kategori","label-for":"Kategori"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","placeholder":"Pilih Kategori","options":_vm.optionsKategori},model:{value:(_vm.selectedKategori),callback:function ($$v) {_vm.selectedKategori=$$v},expression:"selectedKategori"}})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"File Keluhan","label-for":"fileKejadian"}},[_c('b-form-file',{ref:"file",attrs:{"id":"FileSurat","type":"file","placeholder":"Tambah File Keluhan","drop-placeholder":"Drop file here...","multiple":"multiple"},on:{"change":_vm.fileChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Tag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tag","label-for":"tag"}},[_c('b-form-tags',{attrs:{"input-id":"tags-basic","placeholder":"Tambah Tag"},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"keluhan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Isi Keluhan")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Tulis Keluhan","rows":"3"},model:{value:(_vm.complain),callback:function ($$v) {_vm.complain=$$v},expression:"complain"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-button',{staticClass:"bg-gradient-primary mt-2",attrs:{"variant":"outline-primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Kirim")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }