<template>
  <validation-observer
    ref="simpleRules"
    class="brow"
  >
    <b-img
      :src="require('@/assets/images/logo/logo-kop.png')"
      alt="logo"
      width="200"
      style="height: intrinsic;display: block;margin: 50px auto 30px;"
    />

    <b-card-code
      v-if="done"
      style="width: 90%;margin: auto;"
      title="Form Keluhan"
    >
      <div style="font-size: 14px;">
        Terima Kasih telah mengisi form keluhan KAN-Jabung
      </div>
    </b-card-code>

    <b-card-code
      v-else
      style="width: 90%;margin: auto;"
      title="Form Keluhan"
    >
      <b-row class="match-height">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="Perihal"
                placeholder="Tulis Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kontak"
            rules="required"
          >
            <b-form-group
              label="Kontak"
              label-for="Kontak"
            >
              <b-form-input
                id="Kontak"
                v-model="contact"
                placeholder="Tulis Kontak"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Kategori"
            label-for="Kategori"
          >
            <v-select
              v-model="selectedKategori"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih Kategori"
              :options="optionsKategori"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <b-form-group
            label="File Keluhan"
            label-for="fileKejadian"
          >
            <!-- Styled -->
            <b-form-file
              id="FileSurat"
              ref="file"
              v-model="file"
              type="file"
              placeholder="Tambah File Keluhan"
              drop-placeholder="Drop file here..."
              multiple="multiple"
              @change="fileChange"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tag"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="12">

          <validation-provider
            #default="{ errors }"
            name="keluhan"
            rules="required"
          >
            <label for="textarea-default">Isi Keluhan</label>
            <b-form-textarea
              id="textarea-default"
              v-model="complain"
              placeholder="Tulis Keluhan"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-button
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        type="submit"
        @click.prevent="validationForm"
      >
        <span class="align-middle">Kirim</span>
      </b-button>
    </b-card-code>

  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BImg,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BImg,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    ToastificationContent,
    vSelect,
    BFormDatepicker,
  },
  data() {
    return {
      selectedKategori: [],
      selectedKeputusan: [],
      selectedViewers: [],
      selected: [],
      userData: [],
      deadline: null,
      tags: [],
      file: [],
      done: false,
      date: null,
      note: '',
      contact: '',
      complain: '',
      title: '',
      group: '',
      Perihal: '',
      optionsKategori: [
        { value: 'transaksi', text: 'Transaksi' },
        { value: 'NonTransaksi', text: 'Non Transaksi' },
        { value: 'layanan', text: 'Layanan' },
        { value: 'produk', text: 'Produk' },
        { value: 'after selling', text: 'After Selling' },
        { value: 'distribusi', text: 'Distribusi' },
      ],
      options: [
        {
          item: '',
          name: '',
          userID: '',
          receiverType: '',
        },
      ],
      optionsKeputusan: [
        {
          value: '',
          text: '',
          userID: '',
          receiverType: '',
        },
      ],
      optionsViewers: [
        {
          value: '',
          text: '',
          userID: '',
          receiverType: '',
        },
      ],
    }
  },
  watch: {
    selectedKeputusan() {
      this.getRole()
    },
    selected() {
      this.getViewer()
    },
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addKeluhan()
        }
      })
    },
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Complaint',
        },
      })
      this.file = data.data
    },

    async addKeluhan() {
      await axios
        .post('/siap/complaint/external/create', {
          title: this.Perihal,
          contact: this.contact,
          cat: this.selectedKategori.value,
          content: this.complain,
          file: this.file,
          tags: this.tags,
        })
        .then(response => {
          this.done = true
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.field,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 10px;
}
.b-calendar-grid-body {
  .row {
    margin-bottom: unset;
  }
}
.form-group {
  margin-bottom: 5px;
  position: relative;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
.tanggapan2 {
  display: flex;
  align-items: center;
  justify-content: end;
  .avatar {
    width: 80px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  h4 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .input {
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append {
    cursor: pointer;
  }
}
.warning3 {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: 0;
  right: 15px;
}
</style>
